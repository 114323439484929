import Zoom from "react-medium-image-zoom";

import { getCloudinaryImg } from "~/ui/utils.ts";
import { Section } from "./section.tsx";

export const ShowcaseImagesOnly = () => {
  return (
    <div className="w-full columns-2 lg:columns-3">
      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-1_mjxp2g.jpg", "f_auto"),
          loading: "lazy",
          alt: "Création des élèves, petits pots émaillés.",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-1_mjxp2g.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-square w-full rounded"
          alt="Création des élèves, petits pots émaillés."
          width="400px"
        />
      </Zoom>
      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-2_ai4bpe.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-2_ai4bpe.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-video w-full rounded object-cover"
          alt="Création des élèces: 2 petits pots et un couvre beurre avec oreilles de lapin."
          width="400px"
        />
      </Zoom>
      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-4_ortdks.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-4_ortdks.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-square w-full rounded"
          alt="Création des élèves, différents pots émails sombre."
          width="400px"
        />
      </Zoom>
      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-3_app2y7.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-3_app2y7.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-square w-full rounded"
          alt="Création des élèves émaillées et aussi juste cuites sorties du four."
          width="400px"
        />
      </Zoom>
      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-14_uqylhn.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-14_uqylhn.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 w-full rounded"
          alt="Création des élèves, petits pots émaillés."
          width="400px"
        />
      </Zoom>
      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-7_moslr9.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-7_moslr9.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-video w-full rounded"
          alt="Création des élèves, petit cendrier émaillé noir."
          width="400px"
        />
      </Zoom>

      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-8_nyucu9.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-8_nyucu9.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-square w-full rounded"
          alt="Création des élèves, porte oeufs à la coque."
          width="400px"
        />
      </Zoom>
      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-13_pru8dh.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-13_pru8dh.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-square w-full rounded"
          alt="Création des élèves, petits pots émaillés."
          width="400px"
        />
      </Zoom>

      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-6_goluku.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-6_goluku.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-video w-full rounded"
          alt="Création des élèves, pot de fleur lune."
          width="400px"
        />
      </Zoom>

      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-5_jablnk.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-5_jablnk.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-square w-full rounded"
          alt="Création des élèves, pots émaillées mix avec du verre."
          width="400px"
        />
      </Zoom>
      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-des-eleves-10_ebsoxt.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-des-eleves-10_ebsoxt.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-square w-full rounded"
          alt="Création d'élève pot émaillé avec couvercle décoré."
          width="400px"
        />
      </Zoom>

      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-12_ccf7vc.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-12_ccf7vc.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-video w-full rounded"
          alt="Création d'élève tasse émaillée blanc et transparent."
          width="400px"
        />
      </Zoom>

      <Zoom
        zoomImg={{
          src: getCloudinaryImg("/creation-eleves-11_n3m347.jpg", "f_auto"),
          loading: "lazy",
        }}
      >
        <img
          loading="lazy"
          src={getCloudinaryImg(
            "/creation-eleves-11_n3m347.jpg",
            "w_400,f_auto",
          )}
          className="mb-6 aspect-square w-full rounded"
          alt="Créations d'élève, petit plat, pot à cactus et 2 pièces assemblées."
          width="400px"
        />
      </Zoom>
    </div>
  );
};
export const Showcase = () => {
  return (
    <Section
      id="showcase"
      skewed={["skewed-top-left", "skewed-bottom-right"]}
      className="bg-indigo-950"
    >
      <div className="mb-16 flex w-full flex-wrap items-center">
        <div className="mb-8 w-full">
          <span className="font-bold text-purple-200">
            Voici un apercu de ce que vous pourrez créer à l'Atelier
          </span>
          <h2 className="mb-2 font-heading text-4xl font-bold text-white lg:text-5xl">
            Créations des élèves
          </h2>
        </div>
        <ShowcaseImagesOnly />
      </div>
    </Section>
  );
};
