export const SkewedBottom = () => {
  return (
    <div className="skew skew-bottom mr-for-radius">
      <svg
        className="h-8 w-full text-indigo-950 md:h-12 lg:h-20"
        viewBox="0 0 10 10"
        preserveAspectRatio="none"
      >
        <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
      </svg>
    </div>
  );
};

export const SkewedTop = () => {
  return (
    <div className="skew skew-top ml-for-radius">
      <svg
        className="h-8 w-full text-indigo-950 md:h-12 lg:h-20"
        viewBox="0 0 10 10"
        preserveAspectRatio="none"
      >
        <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
      </svg>
    </div>
  );
};

// export const SkewedShape = ({ colorClassName} = )
